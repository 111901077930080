import React, { useState, useEffect } from 'react';
import axios from 'axios';

function FetchButton() {
    const [data, setData] = useState(null);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://www.prome-thus.com:443/api/v1/student');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    return (
        <div>

            <button onClick={fetchData}>Fetch Data</button>
            {data && <div>{JSON.stringify(data)}</div>}
            <button>test</button>
        </div>

    );
}

export default FetchButton;